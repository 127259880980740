import React from "react";
// import "react-table/react-table.css"; Fixme!?
import Autosuggest from "react-autosuggest";
import { theme } from "./search-theme";
import { connect } from "react-redux";
import "./checkout.css";
import { filterEquipmentTypes } from "./checkout_util";

const getSuggestionValue = suggestion => suggestion.type.name;

const renderSuggestion = suggestion => <div>{suggestion.type.name}</div>;

const mapStateToProps = state => {
  return {
    equipmentTypes: state.equipmentTypes,
    equipment: state.equipment,
    storage: state.selectedStorage
  };
};

class CheckoutSearchConnected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      toCheckout: [],
      suggestions: []
    };
    this.onCheckoutItemSelected = props.onCheckoutItemSelected;
  }

  componentDidUpdate = () => {
    if (this.inputRef !== undefined) {
      this.inputRef.className = "form-control";
      this.inputRef.focus();
    }
  };

  onChange = (event, { newValue, method }) => {
    if (method === "enter") {
      this.setState({
        value: ""
      });
      return;
    }
    this.setState({
      value: newValue
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.onCheckoutItemSelected(suggestion);
    this.setState({
      value: ""
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: filterEquipmentTypes(value, this.props.equipment, this.props.storage.id)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Scannen oder tippen...",
      value,
      onChange: this.onChange,
      autoFocus: true
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        highlightFirstSuggestion={true}
        theme={theme}
        autoFocus={true}
        inputRef={this.inputRef}
      />
    );
  }
}

const CheckoutSearch = connect(mapStateToProps)(CheckoutSearchConnected);
export default CheckoutSearch;

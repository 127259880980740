import React from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import "./equipment_table.css";
import EquipmentDetail from "./equipment_detail";
import {connect} from "react-redux";
import {showEquipmentDetail} from "../../redux/actions/equipment-detail-actions";
import 'react-toastify/dist/ReactToastify.css';
import Filterbar from "./equipment_filtering.jsx";
import 'moment-timezone';
import * as moment from "moment";
import {momentFormatShortDE} from "../misc/moment_util";
import {
    filterBySearchString,
    filterExpiryDateBefore,
    filterOnlyUnderstocked,
    sortBySearchstring,
    sumItemAmounts
} from "./filtering_tools";
import {
    ButtonBarContent,
    EquipmentActualCellContent,
    EquipmentDifferenceCellContent,
    EquipmentNextExpiringCellContent,
    UpdatedAtCellContent
} from "./equipment_cell_contents";

const mapStateToProps = state => {
    return {
        equipment: state.equipment,
        storage: state.storage,
        filterEquipmentUnderstocked: state.filterEquipmentUnderstocked,
        equipmentSearchFilter: state.equipmentSearchFilter,
        equipmentExpiryFilter: state.equipmentExpiryFilter,
        selectedStorage: state.selectedStorage,
        loading: state.equipmentLoading,
        updating: state.equipmentUpdating
    };
};

function mapDispatchToProps(dispatch) {
    return {
        showModal: function (item) {
            dispatch(showEquipmentDetail(item));
        },
    };
}

const filterItems = ({equipmentSearchFilter, filterEquipmentUnderstocked, equipmentExpiryFilter, selectedStorage, equipment: equipments}) => {

    let equipmentSuperItems = equipments.filter(item => item.storage === selectedStorage.id);
    equipmentSuperItems.map(
        // calculate difference
        equipment => {
            // set amounts to 0 if they do not exist already
            equipment.actual_amount = !equipment.actual_amount ? 0 : equipment.actual_amount;
            equipment.required_amount = !equipment.required_amount ? 0 : equipment.required_amount;
            equipment.item_amount = !equipment.required_amount ? 0 : equipment.item_amount;

            equipment.difference = (equipment.actual_amount + sumItemAmounts(equipment.items)) - equipment.required_amount;
            return equipment;
        }
    );
    if (filterEquipmentUnderstocked) {
        equipmentSuperItems = filterOnlyUnderstocked(equipmentSuperItems);
    }
    if (equipmentExpiryFilter !== null) {
        equipmentSuperItems = filterExpiryDateBefore(equipmentSuperItems, moment(equipmentExpiryFilter));
    }
    if (equipmentSearchFilter !== '') {
        const searchString = equipmentSearchFilter.toLowerCase();
        equipmentSuperItems = filterBySearchString(equipmentSuperItems, searchString);
        equipmentSuperItems = sortBySearchstring(equipmentSuperItems, searchString);
    }
    return equipmentSuperItems;
};


const ConnectedEquipment = props => {
        moment.updateLocale('de', {
            relativeTime: momentFormatShortDE
        });

        let items = filterItems(props);

        const columns = [
            {
                id: "name",
                style: {textAlign: "left"},
                Header: "Name",
                accessor: e => e.type.name
            },
            {
                id: "required",
                Header: "SOLL",
                accessor: e => e.required_amount,
                maxWidth: 100
            },
            {
                id: "actual",
                Header: "IST",
                Cell: EquipmentActualCellContent,
                maxWidth: 100
            },
            {
                id: "difference",
                Header: "DIFF",
                accessor: item => item.difference,
                Cell: EquipmentDifferenceCellContent,
                maxWidth: 100
            },
            {
                id: "earliest-item-expiry-date-at",
                Header: "Ablauf",
                Cell: EquipmentNextExpiringCellContent,
                maxWidth: 100
            },
            {
                id: "updated-at",
                Header: "Geändert",
                accessor: item => item.updated_at,
                Cell: UpdatedAtCellContent,
                maxWidth: 100
            },
            {
                id: "button-bar",
                Header: "",
                Cell: cellProps => <ButtonBarContent original={cellProps.original} showModal={props.showModal}/>,
            }
        ];

        return (
            <div className="Equipment">
                <Filterbar/>
                <ReactTable data={items} columns={columns}/>
                <EquipmentDetail/>
            </div>
        );
    }
;
const Equipment = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedEquipment);
export default Equipment;

import validator from 'validator';

export const isNotEmpty = value => (value && !validator.isEmpty(value + '') ? undefined : 'Darf nicht leer sein!')
export const required = value => (value || typeof value === 'number' ? undefined : 'Required');
export const isNormalInteger = value => (validator.isInt(value + '') ? undefined : "Muss eine Zahl sein!")

export const numberGeqThanZero = value => (
  validator.isInt(value + '', {
    min: 0
  }) ? undefined : "Muss größer oder gleich 0 sein!");

export const signedNumber = value => (
  validator.isInt(value + '', {}) ? undefined : "Muss eine Zahl sein!");
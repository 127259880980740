import {
    ADD_CHECKIN_ITEM,
    ADD_CHECKOUT_ITEM,
    DELETE_CHECKIN_ITEM,
    DELETE_CHECKOUT_ITEM,
    POST_CHECKIN_FAILURE,
    POST_CHECKIN_REQUEST,
    POST_CHECKIN_SUCCESS,
    POST_CHECKOUT_FAILURE,
    POST_CHECKOUT_REQUEST,
    POST_CHECKOUT_SUCCESS,
    REMOVE_CHECKIN_ITEM,
    REMOVE_CHECKOUT_ITEM
} from "../constants/action-types";

export function checkinReducer(state = [], action) {
    switch (action.type) {
        case ADD_CHECKIN_ITEM:
            return addOrIncreaseItem(state, action.payload);
        case REMOVE_CHECKIN_ITEM:
            return decreaseOrRemoveItem(state, action.payload);
        case DELETE_CHECKIN_ITEM:
            return state.filter(item => item.id !== action.payload.id);
        case POST_CHECKIN_SUCCESS:
            return [];
        default:
            return state;
    }
}

export function checkinLoadingReducer(state = false, action) {
    switch (action.type) {
        case POST_CHECKIN_REQUEST:
            return true;
        case POST_CHECKIN_FAILURE:
        case POST_CHECKIN_SUCCESS:
            return false;
        default:
            return state;
    }
}

export function checkoutReducer(state = [], action) {
    switch (action.type) {
        case ADD_CHECKOUT_ITEM:
            return addOrIncreaseItem(state, action.payload);
        case REMOVE_CHECKOUT_ITEM:
            return decreaseOrRemoveItem(state, action.payload);
        case DELETE_CHECKOUT_ITEM:
            return state.filter(item => item.id !== action.payload.id);

        case POST_CHECKOUT_SUCCESS:
            return [];
        default:
            return state;
    }
}

export function checkoutLoadingReducer(state = false, action) {
    switch (action.type) {
        case POST_CHECKOUT_REQUEST:
            return true;
        case POST_CHECKOUT_SUCCESS:
        case POST_CHECKOUT_FAILURE:
            return false;
        default:
            return state;
    }
}

/**
 * Takes an array of already existing items, checks whether the toAdd object
 * already exists (via their id field). If it does already exist, its 'count'
 * field is increased. If not, it is added to the array with an initial count of
 * 0.
 * @param {Array} currentItems
 * @param {Equipment} toAdd
 */
const addOrIncreaseItem = (currentItems, toAdd) => {
    const possiblyExisting = currentItems.filter(item => item.id === toAdd.id);
    if (possiblyExisting.length === 0) {
        const newItem = {...toAdd};
        newItem.count = 1;
        return [...currentItems, newItem];
    }
    return currentItems.map(item => {
        if (item.id !== toAdd.id) {
            return item;
        }
        return {...item, count: item.count + 1};
    });
};

/**
 * Checks whether or not the element exists in the currentItems array, if not a copy
 * of the array is returned. If it exists, its count is decreased by one. If the
 * count then is equal or less than zero, the item is removed from the returned list.
 * @param {} currentItems
 * @param {*} toDecrease
 */
const decreaseOrRemoveItem = (currentItems, toDecrease) => {
    const items = currentItems.filter(item => item.id === toDecrease.id);
    if (items.length === 0) return currentItems.slice();

    const otherItems = currentItems.filter(item => item.id !== toDecrease.id);

    const newItem = {...items[0]};
    newItem.count--;

    if (newItem.count <= 0) {
        return otherItems;
    }
    return [...otherItems, newItem];
};

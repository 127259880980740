export const momentFormatShortDE ={
      future: "in %s",
      past: "%s",
      s: "einige Sek",
      ss: "%d Sek",
      m: "eine Min",
      mm: "%d Min",
      h: "eine Std",
      hh: "%d Std",
      d: "ein Tag",
      dd: "%d Tage",
      M: "ein Monat",
      MM: "%d Monate",
      y: "ein Jahr",
      yy: "%d Jahre"
    };

export const momentFormatLongDE ={
      future: "in %s",
      past: "%s",
      s: "einige Sekunden",
      ss: "%d Sekunden",
      m: "eine Minute",
      mm: "%d Minuten",
      h: "eine Stunde",
      hh: "%d Stunden",
      d: "ein Tag",
      dd: "%d Tage",
      M: "ein Monat",
      MM: "%d Monate",
      y: "ein Jahr",
      yy: "%d Jahre"
    };
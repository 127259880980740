import React from "react";
import {toast, ToastContainer} from "react-toastify";
import {CHECKING_IN_OUT_TOAST, UPDATING_EQUIPMENT_TOAST} from "../../redux/constants/notification-ids";
import {connect} from "react-redux";

const mapStateToProps = state => {
  return {
    checkinLoading: state.checkinLoading,
    checkoutLoading: state.checkoutLoading
  }
};

// This function is used to provide callbacks to container component.
function mapDispatchToProps(dispatch) {
  return {};
}

const handleCheckoutNotifications = props => {
  let inProgress = props.checkin ? props.checkinLoading : props.checkoutLoading;
  let toastLoadingContent = props.checkin ? "Materialbefüllung läuft..." : "Materialentnahme läuft...";
  let toastSuccessContent = props.checkin ? "Material erfolgreich eingebucht!" : "Materialentnahme erfolgreich!";

  if (inProgress && !toast.isActive(CHECKING_IN_OUT_TOAST)) {
    toast(toastLoadingContent, {
      toastId: CHECKING_IN_OUT_TOAST,
      autoClose: false
    });
  } else if (!inProgress && toast.isActive(CHECKING_IN_OUT_TOAST)) {
    toast.update(CHECKING_IN_OUT_TOAST,
      {
        autoClose: 3000,
        type: toast.TYPE.INFO,
        render: toastSuccessContent
      })
  }
};

const handleEquipmentNotifications = props => {
  if (props.equipmentLoading && !toast.isActive(UPDATING_EQUIPMENT_TOAST)) {
    toast("Item wird aktualisiert...", {
      toastId: UPDATING_EQUIPMENT_TOAST,
      autoClose: false
    });
  } else if (!props.equipmentLoading && toast.isActive(UPDATING_EQUIPMENT_TOAST)) {
    toast.update(UPDATING_EQUIPMENT_TOAST,
      {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
        render: "Item erfolgreich geändert!"
      })
  }
};

const StockmanagerNotifications = props => {
  handleEquipmentNotifications(props);
  handleCheckoutNotifications(props);
  return (<ToastContainer/>);
};

const Notifications = connect(
  mapStateToProps,
  mapDispatchToProps
)(StockmanagerNotifications);
export default Notifications;

import React from 'react'
import {Field, reduxForm} from 'redux-form'
import {Button, FormLabel, FormGroup} from "react-bootstrap";
import InputGrouped from "../misc/misc-input";
import {isNotEmpty} from './login_field_validators';

let LoginForm = props => {
  const {onLogin} = props;
  const handleSubmit = event => {
    event.preventDefault();
    onLogin();
  };
  return <form onSubmit={handleSubmit}>
    <FormGroup>
      <FormLabel>Username</FormLabel>
      <Field name="username" type="text" validate={[isNotEmpty]} component={InputGrouped}/>
    </FormGroup>
    <FormGroup>
      <FormLabel>Password</FormLabel>
      <Field name="password" type="password" validate={[isNotEmpty]} component={InputGrouped}/>
    </FormGroup>
    <Button type="submit">Anmelden</Button>
  </form>
};

LoginForm = reduxForm({
  form: 'login'
})(LoginForm);

export default LoginForm;
import React from 'react';
import {Form} from "react-bootstrap";

class InputGrouped extends React.Component {
    render() {
        const {
            feedbackIcon,
            input,
            label,
            type,
            placeholder,
            meta: {error, warning, touched},
            ...props
        } = this.props;

        let message;
        const validationState = (touched && (error && "error")) || (warning && "warning") || null;

        if (touched && (error || warning)) {
            message = <span className="help-block">{error || warning}</span>;
        }

        let labelComp = null;
        if (label) {
            labelComp = <Form.Label>{label}</Form.Label>;
        }

        return (
            <Form.Group>
                {labelComp}
                <Form.Control {...input}
                              type={type}
                              placeholder={placeholder}
                              {...props} />
                {feedbackIcon ?
                    <Form.Control.Feedback valid={validationState}>{feedbackIcon}</Form.Control.Feedback> : null}
                {message}
            </Form.Group>
        );
    }
}

export default InputGrouped;
import {
    FETCH_EQUIPMENT_FAILURE,
    FETCH_EQUIPMENT_REQUEST,
    FETCH_EQUIPMENT_SUCCESS,
    HIDE_EQUIPMENT_DETAIL,
    RESET_EXPIRY_FILTER,
    RESET_SEARCH_FILTER,
    SHOW_EQUPIMENT_DETAIL,
    TOGGLE_FILTER_UNDERSTOCKED,
    UDPATE_EXPIRY_FILTER,
    UDPATE_SEARCH_FILTER,
    UPDATE_EQUIPMENT_FAILURE,
    UPDATE_EQUIPMENT_REQUEST,
    UPDATE_EQUIPMENT_SUCCESS
} from "../constants/action-types";

export const equipmentReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_EQUIPMENT_REQUEST:
            return state;
        case FETCH_EQUIPMENT_SUCCESS:
            return action.payload;
        case FETCH_EQUIPMENT_FAILURE:
            return state;
        default:
            return state;
    }
};

export const equipmentDetailReducer = (
    state = {show: false, item: {}},
    action
) => {
    switch (action.type) {
        case UPDATE_EQUIPMENT_SUCCESS:
        case HIDE_EQUIPMENT_DETAIL:
            return {...state, show: false};
        case SHOW_EQUPIMENT_DETAIL:
            return {show: true, item: action.payload};
        default:
            return state;
    }
};

export const equipmentUnderstockedReducer = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_FILTER_UNDERSTOCKED:
            return action.payload;

        default:
            return state;
    }
};

export const equipmentExpiryReducer = (state = null, action) => {
    switch (action.type) {
        case UDPATE_EXPIRY_FILTER:
            return action.payload;
        case RESET_EXPIRY_FILTER:
            return null;
        default:
            return state;
    }
};

export const equipmentUpdateSearchFilterReducer = (state = "", action) => {
    switch (action.type) {
        case UDPATE_SEARCH_FILTER:
            return action.payload;
        case RESET_SEARCH_FILTER:
            return "";
        default:
            return state;
    }
};

export const equipmentLoadingReducer = (state = false, action) => {
    switch (action.type) {
        case FETCH_EQUIPMENT_REQUEST:
            return true;
        case FETCH_EQUIPMENT_FAILURE:
        case FETCH_EQUIPMENT_SUCCESS:
            return false;

        default:
            return state;
    }
};

export const equipmentUpdatingReducer = (state = false, action) => {
    switch (action.type) {
        case UPDATE_EQUIPMENT_REQUEST:
            return true;
        case UPDATE_EQUIPMENT_SUCCESS:
        case UPDATE_EQUIPMENT_FAILURE:
            return false;

        default:
            return state;
    }
};

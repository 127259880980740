export const FETCH_STORAGES_REQUEST = "FETCH_STORAGES_REQUEST";
export const FETCH_STORAGES_FAILURE = "FETCH_STORAGES_FAILURE";
export const FETCH_STORAGES_SUCCESS = "FETCH_STORAGES_SUCCESS";

export const FETCH_EQUIPMENT_REQUEST = "FETCH_EQUIPMENT_REQUEST";
export const FETCH_EQUIPMENT_FAILURE = "FETCH_EQUIPMENT_FAILURE";
export const FETCH_EQUIPMENT_SUCCESS = "FETCH_EQUIPMENT_SUCCESS";

export const UPDATE_EQUIPMENT_REQUEST = "UPDATE_EQUIPMENT_REQUEST";
export const UPDATE_EQUIPMENT_FAILURE = "UPDATE_EQUIPMENT_FAILURE";
export const UPDATE_EQUIPMENT_SUCCESS = "UPDATE_EQUIPMENT_SUCCESS";

export const FETCH_EQUIPMENT_TYPES_REQUEST = "FETCH_EQUIPMENT_TYPES_REQUEST";
export const FETCH_EQUIPMENT_TYPES_FAILURE = "FETCH_EQUIPMENT_TYPES_FAILURE";
export const FETCH_EQUIPMENT_TYPES_SUCCESS = "FETCH_EQUIPMENT_TYPES_SUCCESS";

export const CHANGE_SELECTED_STORAGE = "CHANGE_SELECTED_STORAGE";
export const SELECT_DEFAULT_STORAGE = "SELECT_DEFAULT_STORAGE";

export const ADD_CHECKOUT_ITEM = "ADD_CHECKOUT_ITEM";
export const REMOVE_CHECKOUT_ITEM = "REMOVE_CHECKOUT_ITEM";
export const DELETE_CHECKOUT_ITEM = "DELETE_CHECKOUT_ITEM";

export const POST_CHECKOUT_REQUEST = "POST_CHECKOUT_REQUEST";
export const POST_CHECKOUT_SUCCESS = "POST_CHECKOUT_SUCCESS";
export const POST_CHECKOUT_FAILURE = "POST_CHECKOUT_FAILURE";

export const ADD_CHECKIN_ITEM = "ADD_CHECKIN_ITEM";
export const REMOVE_CHECKIN_ITEM = "REMOVE_CHECKIN_ITEM";
export const DELETE_CHECKIN_ITEM = "DELETE_CHECKIN_ITEM";

export const POST_CHECKIN_REQUEST = "POST_CHECKIN_REQUEST";
export const POST_CHECKIN_SUCCESS = "POST_CHECKIN_SUCCESS";
export const POST_CHECKIN_FAILURE = "POST_CHECKIN_FAILURE";

export const HIDE_EQUIPMENT_DETAIL = "HIDE_EQUIPMENT_DETAIL";
export const SHOW_EQUPIMENT_DETAIL = "SHOW_EQUPIMENT_DETAIL";

export const TOGGLE_FILTER_UNDERSTOCKED = "TOGGLE_FILTER_UNDERSTOCKED";

export const UDPATE_SEARCH_FILTER = "UDPATE_SEARCH_FILTER";
export const RESET_SEARCH_FILTER = "RESET_SEARCH_FILTER";

export const UDPATE_EXPIRY_FILTER = "UDPATE_EXPIRY_FILTER";
export const RESET_EXPIRY_FILTER = "RESET_EXPIRY_FILTER";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT = "LOGOUT";
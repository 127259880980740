import {
  CHANGE_SELECTED_STORAGE,
  FETCH_STORAGES_FAILURE,
  FETCH_STORAGES_REQUEST,
  FETCH_STORAGES_SUCCESS,
  SELECT_DEFAULT_STORAGE
} from "../constants/action-types";
import {API_URL} from "../constants/urls";
import {getStandardHeader} from "../constants/headers";

export function fetchStorages() {
  return function (dispatch) {
    // Dispatching REQUEST action, which tells our app, that we are started requesting todos.
    dispatch({
      type: FETCH_STORAGES_REQUEST
    });
    return (
      fetch(API_URL + "storage/?format=json", {headers: getStandardHeader()})
      // And providing `response` and `body` variables to the next chain.
        .then(response => response.json().then(body => ({
          response,
          body
        })))
        .then(({
                 response,
                 body
               }) => {
          if (!response.ok) {
            // If request was failed, dispatching FAILURE action.
            dispatch({
              type: FETCH_STORAGES_FAILURE,
              error: body.error
            });
          } else {
            // When everything is ok, dispatching SUCCESS action.
            dispatch({
              type: FETCH_STORAGES_SUCCESS,
              payload: body
            });
            dispatch({
              type: SELECT_DEFAULT_STORAGE,
              payload: body[0]
            });
          }
        })
    )
      ;
  };
}

export const changeSelectedStorage = storage => {
  return function (dispatch) {
    // fetchStorages(storage);
    dispatch(selectStorage(storage));
    // dispatch(fetchEquipments());
  };
};

const selectStorage = storage => {
  return function (dispatch) {
    dispatch({
      type: CHANGE_SELECTED_STORAGE,
      payload: storage
    });
  };
};
export const calc_number_of_items_in_stock = (equipments) => {
    let actual_amounts = equipments.map((item) => {
        return item.actual_amount;
    }, 0);

    return actual_amounts.reduce((acc, cur) => {
        return cur + acc;
    }, 0);
};

export const calc_number_of_equipments = (equipments) => {
    return equipments.length;
};

export const calc_number_of_understocked_equipments = (equipments) => {
    let understocked_equipments = equipments.filter((item) => {
        return item.actual_amount < item.required_amount;
    });
    return understocked_equipments.length;
};

export const filter_by_storage = (equipments, storage) => {
    return equipments.filter((item) => {
        return item.storage === storage.id;
    });
};

import React from "react";
import {connect} from "react-redux";

import {loginFromForm} from "../../redux/actions/login-actions";
import LoginForm from "./login-form";
import {Redirect} from "react-router-dom";
import {Alert, Modal} from "react-bootstrap";

const mapStateToProps = state => {
    return {
        authtoken: state.auth.token,
        failure: state.auth.failure,
        loading: state.auth.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // This function will be available in component as `this.props.fetchTodos`
        login: function () {
            dispatch(loginFromForm());
        },
    };
};

const LoginConnected = props => {
    if (localStorage.getItem("token") !== null) {
        return <Redirect to='/app/'/>
    }

    let statusAlert = <div></div>;
    if (props.failure) {
        statusAlert = <div>
            <Alert variant={"danger"}>
                <p>Benutzername oder Passwort falsch!</p>
            </Alert>
        </div>
    } else if (props.loading) {
        statusAlert = <div>
            <Alert variant={"info"}>
                <p>Lädt...</p>
            </Alert>
        </div>
    }
    return <Modal show={true}>
        <Modal.Header>
            <h1>Lager-Anmeldung</h1>
        </Modal.Header>
        <Modal.Body>
            <LoginForm onLogin={props.login}/>
        </Modal.Body>
        {statusAlert}
    </Modal>
};

const Login = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginConnected);
export default Login;

import React from 'react'
import {Field, reduxForm} from 'redux-form'
import {Button, Form, FormGroup, FormLabel} from "react-bootstrap";
import InputGrouped from "../misc/misc-input";
import {isNormalInteger, isNotEmpty, numberGeqThanZero} from './equipment_field_validators';

let EquipmentForm = props => {
    const {handleSubmit} = props;
    return (<form onSubmit={handleSubmit}>
        <Form.Text>Diese Werte werden für alle Einträge mit diesem Namen übernommen, auch in anderen
            Lagern!</Form.Text>
        <FormGroup>
            <FormLabel>Name</FormLabel>
            <Field name="type.name" type="text" validate={[isNotEmpty]} component={InputGrouped}/>
        </FormGroup>
        <FormGroup>
            <FormLabel>Suchbegriffe (durch Komma getrennt)</FormLabel>
            <Field name="type.synonyms" type="text" component={InputGrouped}/>

        </FormGroup>
        <hr/>
        <Form.Text>Spezifisch für diesen Eintrag, in diesem Lager</Form.Text>
        <FormGroup>
            <FormLabel>Soll-Bestand</FormLabel>
            <Field name="required_amount" type="number" validate={[isNormalInteger, numberGeqThanZero]}
                   component={InputGrouped}/>
        </FormGroup>
        <FormGroup>
            <FormLabel>Ist-Bestand ohne Einzelauflistung</FormLabel>
            <Field name="actual_amount" type="number" validate={[isNormalInteger]}
                   component={InputGrouped}/>
        </FormGroup>
        <FormGroup>
            <FormLabel>Kommentar</FormLabel>
            <Field name="comment" type="text" component={InputGrouped}/>
        </FormGroup>
        <Button onClick={handleSubmit}>Senden</Button>
    </form>);
};

EquipmentForm = reduxForm({
    form: 'equipmentDetail'
})(EquipmentForm);

export default EquipmentForm
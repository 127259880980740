import React from "react";
import { Field, reduxForm } from "redux-form";
import { FormLabel, FormGroup } from "react-bootstrap";
import InputGrouped from "../misc/misc-input";
import {
  isNormalInteger,
  numberGeqThanZero
} from "../equipment/equipment_field_validators";
import * as moment from "moment";
import { momentFormatLongDE } from "../misc/moment_util";

class StocktakingForm extends React.Component {
  handleKeypress = event => {
    if (event.key === "Enter") {
      this.props.onSubmit();
    }
    if (event.key === "Escape" || event.key === "q") {
      this.props.onCancel();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeypress, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeypress, false);
  }

  render() {
    moment.updateLocale("de", {
      relativeTime: momentFormatLongDE
    });
    let lastChanged =
      this.props.initialValues && moment(this.props.initialValues.updated_at).fromNow();

    return (
      <form onSubmit={this.handleSubmit}>
        <h2>
          zuletzt geändert: vor <b>{lastChanged}</b>
        </h2>
        <FormGroup>
          <FormLabel>
            <h2>Ist-Bestand</h2>
          </FormLabel>
          <Field
            name="actual_amount"
            type="number"
            validate={[isNormalInteger]}
            component={InputGrouped}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Soll-Bestand</FormLabel>
          <Field
            name="required_amount"
            type="number"
            validate={[isNormalInteger, numberGeqThanZero]}
            component={InputGrouped}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Kommentar</FormLabel>
          <Field name="comment" type="text" component={InputGrouped} />
        </FormGroup>
      </form>
    );
  }
}

StocktakingForm = reduxForm({
  form: "equipmentDetail" // same functionality as equipment detail!
})(StocktakingForm);

export default StocktakingForm;

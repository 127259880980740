import React from "react";
import "react-table-6/react-table.css";
import {Badge, Button, Dropdown, DropdownButton, Form, InputGroup, Navbar} from "react-bootstrap";
import {connect} from "react-redux";
import {
    resetExpiryFilter,
    resetSearchFilter,
    toggleFilterUnderstocked,
    updateExpiryFilter,
    updateSearchFilter
} from "../../redux/actions/equipment-actions";
import Switch from "react-switch";
import {FaTrash} from "react-icons/fa";
import {getNextQuarterDates} from "./filtering_tools";

const mapStateToProps = state => {
    return {
        isFiltering: state.filterEquipmentUnderstocked,
        searchFilterText: state.equipmentSearchFilter,
        expiryFilterDate: state.equipmentExpiryFilter,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        toggleUnderstockedFiltering: (filterState) => {
            dispatch(toggleFilterUnderstocked(filterState));
        },
        updateSearchFilter: (searchText) => {
            dispatch(updateSearchFilter(searchText));
        },
        resetSearchFilter: () => {
            dispatch(resetSearchFilter());
        },
        updateExpiryFilter: (latestExpiryDate) => {
            dispatch(updateExpiryFilter(latestExpiryDate));
        },
        resetExpiryFilter: () => {
            dispatch(resetExpiryFilter());
        }
    };
}

const momentToQuarterString = (quarterMoment) => {
    const quarterIndex = quarterMoment.quarter();
    const quarterYear = quarterMoment.year();
    return "Q" + quarterIndex + "/" + quarterYear;
};

function makeDropdownItems(onChange) {
    let quarterEndMoments = getNextQuarterDates();
    return quarterEndMoments.map((quarterMoment) => {
        let quarterString = momentToQuarterString(quarterMoment);
        return <Dropdown.Item onClick={() => onChange(quarterMoment)}
                             key={quarterString}>{quarterString}</Dropdown.Item>
    });
}

const DropdownText = (currentlySelected) => {
    return currentlySelected == null ? <span>Ablauf bis Quartal</span> :
        <span>{"Ablauf bis "}<Badge pill variant="light">{momentToQuarterString(currentlySelected)}</Badge></span>;
};

function makeDropdownButtonVariant(currentlySelected) {
    return currentlySelected == null ? "grey" : "danger";
}

const ExpiryQuarterFilterSelector = ({currentlySelected, onChange, onReset}) => {
    let dropdownItems = makeDropdownItems(onChange);
    const dropdownTitle = DropdownText(currentlySelected);
    const buttonVariant = makeDropdownButtonVariant(currentlySelected);

    return <DropdownButton variant={buttonVariant}
                           id={"expiry-quarter-filter"}
                           title={dropdownTitle}
                           style={{marginLeft: 10}}>
        {dropdownItems}
        <Dropdown.Divider/>
        <Dropdown.Item as={"button"} onClick={onReset}>Zurücksetzen</Dropdown.Item>
    </DropdownButton>
};

const UnderStockedSwitch = ({onChange, isChecked}) => {
    return <Switch offColor={"#858796"}
                   onColor={"#e74a3b"}
                   checkedIcon={false}
                   uncheckedIcon={false}
                   onChange={onChange}
                   checked={isChecked}/>
};

const ConnectedFilterbar = ({
                                searchFilterText, isFiltering, toggleUnderstockedFiltering, updateSearchFilter,
                                resetSearchFilter, updateExpiryFilter, resetExpiryFilter, expiryFilterDate
                            }) => {
        const handleOnChangeSearchFilter = (event) => {
            updateSearchFilter(event.target.value);
        };
        const handleOnResetSearchBar = (event) => {
            event.target.value = "";
            resetSearchFilter();
        };
        const handleToggleFiltering = (checked) => {
            toggleUnderstockedFiltering(checked);
        };

        return (
            <Navbar>
                <Form onSubmit={event => event.preventDefault()} inline>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Suche</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control onChange={handleOnChangeSearchFilter} type={"text"} value={searchFilterText}/>
                            <InputGroup.Append>
                                <InputGroup.Text as={Button} onClick={handleOnResetSearchBar}>
                                    <FaTrash/>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group style={{paddingLeft: "1em", paddingTop: "0.5em"}}>
                        <Form.Label style={{paddingRight: ".25em"}}>
                            Nur Fehlbestände
                        </Form.Label>
                        <UnderStockedSwitch onChange={handleToggleFiltering} isChecked={isFiltering}/>
                    </Form.Group>
                    <Form.Group>
                        <ExpiryQuarterFilterSelector currentlySelected={expiryFilterDate} onChange={updateExpiryFilter}
                                                     onReset={resetExpiryFilter}/>
                    </Form.Group>
                </Form>
            </Navbar>
        );
    }
;

const Filterbar = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedFilterbar);
export default Filterbar;

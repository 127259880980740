import React from "react";
// import "react-table/react-table.css"; fixme
import { ListGroup, ListGroupItem } from "react-bootstrap";

const Storage = props => {
  let storageEntries = [];
  storageEntries = props.storages.map(item => (
    <ListGroupItem
      onClick={() => props.onSelected(item)}
      key={item.id}
      active={props.selected.id === item.id}
    >
      {item.name}
    </ListGroupItem>
  ));
  return <ListGroup id="storage-list">{storageEntries}</ListGroup>;
};

export default Storage;

import React from "react";
import CheckoutSearch from "../checkout/checkout_search";
import {connect} from "react-redux";
import {Button, Modal, Card, Form} from "react-bootstrap";
import StocktakingForm from "./stocktaking-form";
import {updateEquipmentFromForm} from "../../redux/actions/equipment-actions";

const mapStateToProps = state => {
    return {
        equipmentTypes: state.equipmentTypes,
    };
};

// This function is used to provide callbacks to container component.
function mapDispatchToProps(dispatch) {
    return {
        updateEquipment: function () {
            dispatch(updateEquipmentFromForm());
        }
    };
}

class StocktakingConnected extends React.Component {
    constructor(props) {
        super(props);
        this.state = {currentItem: null};
    }

    handleSubmit = event => {
        this.setState({currentItem: null});
        this.props.updateEquipment();
    };

    handleCancel = event => {
        this.setState({currentItem: null});
    };

    render() {
        return (
            <div>
                <h1>Inventarisierung</h1>
                <Card className={"shadow border-left-secondary"}>
                    <Card.Body>
                        <Card.Title>Barcode scannen oder Namen eingeben</Card.Title>
                        <Form onSubmit={event => event.preventDefault}>
                            <Form.Group>
                                <CheckoutSearch
                                    onCheckoutItemSelected={item => {
                                        this.setState({currentItem: item});
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
                <Modal show={this.state.currentItem != null}>
                    <Modal.Header>
                        <h1>
                            {this.state.currentItem && this.state.currentItem.type.name}{" "}
                        </h1>
                    </Modal.Header>
                    <Modal.Body>
                        <StocktakingForm
                            initialValues={this.state.currentItem}
                            onSubmit={this.handleSubmit}
                            onCancel={this.handleCancel}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCancel}>Abbrechen</Button>
                        <Button variant="primary" onClick={this.handleSubmit}>
                            Senden
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const Stocktaking = connect(
    mapStateToProps,
    mapDispatchToProps
)(StocktakingConnected);
export default Stocktaking;

import React from "react";
import {ListGroup, ListGroupItem} from "react-bootstrap";
import "./checkout_list.css";
import {connect} from "react-redux";
import {
    addCheckinItem,
    addCheckoutItem,
    deleteCheckinItem,
    deleteCheckoutItem,
    removeCheckinItem,
    removeCheckoutItem
} from "../../redux/actions/checkout-actions";
import {CheckoutEntry} from "./checkout_list_entry";

const mapStateToProps = state => {
    return {
        checkoutItems: state.checkoutItems,
        checkinItems: state.checkinItems
    };
};

function mapDispatchToProps(dispatch) {
    return {
        // This function will be available in component as `this.props.fetchTodos`
        addCheckinItem: item => {
            dispatch(addCheckinItem(item));
        },
        addCheckoutItem: item => {
            dispatch(addCheckoutItem(item));
        },
        removeCheckoutItem: item => {
            dispatch(removeCheckoutItem(item));
        },
        removeCheckinItem: item => {
            dispatch(removeCheckinItem(item));
        },
        deleteCheckoutItem: item => {
            dispatch(deleteCheckoutItem(item));
        },
        deleteCheckinItem: item => {
            dispatch(deleteCheckinItem(item));
        }
    };
}

const CheckoutListConnected = props => {
    var onPlus = item => props.addCheckoutItem(item);
    var onMinus = item => props.removeCheckoutItem(item);
    var onDelete = item => props.deleteCheckoutItem(item);
    if (props.checkin) {
        onPlus = item => props.addCheckinItem(item);
        onMinus = item => props.removeCheckinItem(item);
        onDelete = item => props.deleteCheckinItem(item);
    }

    var listItems = [];
    if (props.checkin) {
        listItems = props.checkinItems;
    } else {
        listItems = props.checkoutItems;
    }
    listItems = listItems.map(item => (
        <CheckoutEntry
            item={item}
            onDelete={onDelete}
            onPlus={onPlus}
            onMinus={onMinus}
        />
    ));
    if (listItems.length === 0) {
        listItems = [
            <ListGroupItem key={0}>
                <p style={{color: "#cccccc"}}>
                    <i>- leer -</i>
                </p>
            </ListGroupItem>
        ];
    }

    return (
        <div style={{marginBottom: "2em"}}>
            <ListGroup className={"shadow"}>{listItems}</ListGroup>
        </div>
    );
};

const CheckoutList = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutListConnected);
export default CheckoutList;

/**
 * Returns an array of items, where every item has a 'difference' of less than 0.
 *
 * @param items List of items.
 */
import moment from "moment";

export const filterOnlyUnderstocked = (items) => {
    if (!Array.isArray(items)) {
        return items;
    }
    return items.filter(item => item.difference < 0);
};

/**
 * Filters a list of items by checking if
 *  - the name
 *  - the synonyms field
 * contains the search string.
 *
 * @param items List of items.
 * @param searchString String to search for.
 */
export const filterBySearchString = (items, searchString) => {
    if (!Array.isArray(items)) {
        return items;
    }

    return items.filter(
        item => {
            let contains = false;
            if (item.type && item.type.name) {
                contains = item.type.name.toLowerCase().includes(searchString);
            }
            if (item.type.synonyms && !contains) {
                contains = item.type.synonyms.toLowerCase().includes(searchString)
            }
            return contains;
        }
    );
};

/**
 * Sorts the list by which item contains the string first.
 *
 * @param items
 * @param searchString
 */
export const sortBySearchstring = (items, searchString) => {
    return items.sort((a, b) => {
        if (!a.type || !a.type.name || !b.type || !b.type.name) {
            return 0;
        }
        let aNames = a.type.name.toLowerCase();
        let bNames = b.type.name.toLowerCase();
        if (a.type.synonyms) {
            aNames += a.type.synonyms.toLowerCase();
        }
        if (b.type.synonyms) {
            bNames += b.type.synonyms.toLowerCase();
        }
        let aPosition = aNames.indexOf(searchString);
        let bPosition = bNames.indexOf(searchString);
        return aPosition - bPosition;
    });
};

export const sumItemAmounts = (items) => {
    return items.reduce((prev, curr) => {
        return prev + curr.amount;
    }, 0);
};

export const hasItemExpiringBefore = (items, latestExpiryMoment) => {
    for (let i = 0; i < items.length; i++) {
        if (moment(items[i].date_of_expiry).isBefore(latestExpiryMoment))
            return true;
    }
    return false;
};

export const getEarliestExpirationDateFromItems = (items) => {
    let momentItems = items.map(item => {
        return moment(item.date_of_expiry, "YYYY-MM-DD");
    });
    momentItems.sort((a, b) => {
        return a.isBefore(b) ? -1 : 1;
    });
    return momentItems[0];
};

export const filterExpiryDateBefore = (superItems, latestExpiryMoment) => {
    return superItems.filter(superItem => {
        return hasItemExpiringBefore(superItem.items, latestExpiryMoment);
    });
};

/**
 * Returns the end dates of the next 4 quarters as Moment.js objects.
 */
export const getNextQuarterDates = () => {
    let thisQuarterBegin = moment().startOf("quarter");
    let result = [];
    for (let i = 0; i < 4; i++) {
        let currentQuarter = moment(thisQuarterBegin).add(i, "quarter");
        let quarterEndDate = moment(currentQuarter).endOf("quarter");
        result.push(quarterEndDate);
    }
    return result;
};

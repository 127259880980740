import React from "react";
import "./App.css";
import {connect} from "react-redux";
import Notifications from "./notifications/stockmanager_toast";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Warehouse from "./warehouse/warehouse-main";
import Login from "./auth/login-modal";

function mapStateToProps(state) {
  return {};
}

// This function is used to provide callbacks to container component.
function mapDispatchToProps(dispatch) {
  return {};
}


const App = (props) => {
  return <div className="App">
    <Router>
      <Switch>
        <Route path="/app/" component={Warehouse}/>
        <Route path="/login/" component={Login}/>
        <Redirect to={"/app/equipment/"}/>
      </Switch>
    </Router>
    <Notifications/>
  </div>
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

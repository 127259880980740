import React from "react";
// import "react-table/react-table.css"; fixme?!
import CheckoutSearch from "./checkout_search";
import CheckoutList from "./checkout_list";
import {Card, Form, FormGroup} from "react-bootstrap";
import {connect} from "react-redux";
import {addCheckinItem, addCheckoutItem, postCheckin, postCheckout} from "../../redux/actions/checkout-actions";
import CheckoutButton from "./checkout_button";

const mapStateToProps = state => {
    return {
        equipmentTypes: state.equipmentTypes,
    };
};

// This function is used to provide callbacks to container component.
function mapDispatchToProps(dispatch) {
    return {
        // This function will be available in component as `this.props.fetchTodos`
        addCheckinItem: function (item) {
            dispatch(addCheckinItem(item));
        },
        addCheckoutItem: function (item) {
            dispatch(addCheckoutItem(item));
        },
        postCheckout: function () {
            dispatch(postCheckout());
        },
        postCheckin: function () {
            dispatch(postCheckin());
        }
    };
}


class CheckoutConnected extends React.Component {
    render() {
        let heading = "Entnahme";
        let cardBorderClass = "border-left-warning";
        this.onCheckoutItemSelected = this.props.addCheckoutItem;
        this.onCheckoutButtonClicked = this.props.postCheckout;
        if (this.props.checkin) {
            heading = "Auffüllen";
            cardBorderClass = "border-left-success";
            this.onCheckoutItemSelected = this.props.addCheckinItem;
            this.onCheckoutButtonClicked = this.props.postCheckin;
        }
        return (
            <div style={{width: 'auto'}}>
                <h1>{heading}</h1>
                <Card style={{marginBottom: ".75em"}} className={"shadow "+ cardBorderClass}>
                    <Card.Body>
                        <Card.Title>
                            Barcode scannen oder Namen eingeben
                        </Card.Title>
                        <Form onSubmit={event => event.preventDefault()}>
                            <FormGroup>
                                <CheckoutSearch
                                    onCheckoutItemSelected={this.onCheckoutItemSelected}
                                />
                            </FormGroup>
                        </Form>
                    </Card.Body>
                </Card>
                <CheckoutList
                    checkin={this.props.checkin}
                    onCheckoutButtonClicked={this.onCheckoutButtonClicked}
                />
                <CheckoutButton
                    checkin={this.props.checkin}
                    onClick={this.onCheckoutButtonClicked}
                />
            </div>
        );
    }
}

const Checkout = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutConnected);
export default Checkout;

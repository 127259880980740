import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import store from "./redux/store/";
import {Provider} from "react-redux";
import '../src/bootstrap/sb-admin-2.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import 'moment/locale/de';

ReactDOM.render(<Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById("root")
);
// registerServiceWorker();

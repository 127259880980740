import Moment from "react-moment";
import {Badge, Button, OverlayTrigger, Popover, Table, Tooltip} from "react-bootstrap";
import * as moment from "moment";
import React from "react";
import {FaComment, FaList, FaPen} from "react-icons/fa";
import {getEarliestExpirationDateFromItems, sumItemAmounts} from "./filtering_tools";

export const EquipmentItemsTable = ({items, amountNonExpiring}) => {

    const tableRowsExpiringItems = items.map(item => {
        return <tr>
            <td align={"center"}><b>{item.amount}</b></td>
            <td><Moment format={"DD.MM.YYYY"} locale={"de"}>{item.date_of_expiry}</Moment></td>
            <td><Moment fromNow locale={"de"}>{item.date_of_expiry}</Moment></td>
        </tr>
    });

    let metaRowNonExpiring = <tr>
        <td align={"center"}><b>{amountNonExpiring}</b></td>
        <td align={"center"}>ohne Ablauf</td>
    </tr>;
    if (amountNonExpiring === 0)
        metaRowNonExpiring = null;

    return <Table bordered>
        <thead>
        <tr>
            <th>Anzahl</th>
            <th>Ablaufdatum</th>
            <th>in</th>
        </tr>
        </thead>
        <tbody>
        {metaRowNonExpiring}
        {tableRowsExpiringItems}
        </tbody>
    </Table>
};

export const EquipmentActualCellContent = ({original}) => {
    let value = original.actual_amount;
    let itemsValues = sumItemAmounts(original.items);
    value += itemsValues;
    return <div>{value}</div>;
};

const ShowItemButton = (props) => {
    let itemLabelVisibility = 'hidden';
    if (props.original.items.length > 0)
        itemLabelVisibility = 'visible';

    let popover = <Popover id={"popover-items"} className={"shadow"}>
        <Popover.Title as={"h3"}>Auflistung</Popover.Title>
        <Popover.Content>
            <EquipmentItemsTable items={props.original.items} amountNonExpiring={props.original.actual_amount}/>
        </Popover.Content>
    </Popover>;

    return <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
        <Button variant={"secondary"} style={{visibility: itemLabelVisibility, marginLeft: 10}}>
            <FaList/>
        </Button>
    </OverlayTrigger>
};

export const EquipmentNextExpiringCellContent = ({original}) => {
    if (original.items.length === 0)
        return <div>---</div>;
    let resultDate = getEarliestExpirationDateFromItems(original.items);
    const resultString = resultDate.format("MM/YYYY");

    let stringStyle = {};
    if (resultDate.isBefore(moment().endOf("quarter")))
        stringStyle = {color: "var(--danger)"};

    return <div style={stringStyle}>{resultString}</div>;
};


export const UpdatedAtCellContent = (props) => {
    return <OverlayTrigger placement={"top"} overlay={
        <Tooltip id={"updated-at-tooltip-" + props.original.id}>
            <Moment format={"dddd, Do MMMM YYYY, LTS [Uhr]"}
                    locale={"de"}>{props.original.updated_at}</Moment>
        </Tooltip>}>
        <Badge variant={"light"}>
            <Moment fromNow locale={"de"}>{props.original.updated_at}</Moment>
        </Badge>
    </OverlayTrigger>
};

export const EquipmentDifferenceCellContent = (props) => {
    let difference = props.original.difference;
    let style = "success";
    if (difference < 0) {
        style = "danger";
    } else if (difference > 0) {
        difference = "+" + difference;
    }
    return <Badge variant={style}>{difference}</Badge>;
};

const ShowCommentButton = ({comment}) => {
    let commentLabelVisibility = 'hidden';
    if (comment)
        commentLabelVisibility = 'visible';

    let popover = <Popover id="comment-tooltip">
        <Popover.Title as={"h6"}>Kommentar</Popover.Title>
        <Popover.Content>
            <span className={"text-gray-900"}>{comment}</span>
        </Popover.Content>
    </Popover>;

    return <OverlayTrigger overlay={popover} trigger={"click"} rootClose>
        <Button variant="info"
                style={{marginLeft: 10, visibility: commentLabelVisibility}}>
            <FaComment/>
        </Button>
    </OverlayTrigger>
};

export const ButtonBarContent = (props) => {
    return <div>
        <Button variant={"secondary"} onClick={() => props.showModal(props.original)}>
            <FaPen/>
        </Button>
        <ShowItemButton original={props.original}/>
        <ShowCommentButton comment={props.original.comment}/>
    </div>
};


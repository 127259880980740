import React, {Component} from "react";
import Equipment from "../equipment/equipment";
import Checkout from "../checkout/checkout";
import Storage from "../storage/storage-selection";
import {Badge, Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import {fetchStorageAndEquipmentInitial} from "../../redux/actions/index";
import {changeSelectedStorage, fetchStorages} from "../../redux/actions/storage-actions";
import {connect} from "react-redux";
import {Link, Redirect, Route} from "react-router-dom";
import {logout} from "../../redux/actions/login-actions";
import Stocktaking from "../stocktaking/stocktaking";
import Dashboard from "../dashboard/dashboard";

function mapStateToProps(state) {
    return {
        authtoken: state.auth.token,
        equipment: state.equipment,
        storages: state.storages,
        selectedStorage: state.selectedStorage,
        checkoutItems: state.checkoutItems,
        checkinItems: state.checkinItems,
        equipmentLoading: state.equipmentLoading
    };
}

// This function is used to provide callbacks to container component.
function mapDispatchToProps(dispatch) {
    return {
        fetchStorages: function () {
            dispatch(fetchStorages());
        },
        fetchDataInitial: function () {
            dispatch(fetchStorageAndEquipmentInitial());
        },
        changeSelectedStorage: function (storage) {
            dispatch(changeSelectedStorage(storage));
        },
        logout: function () {
            dispatch(logout());
        }
    };
}

const createCheckInOutTitle = (isCheckIn, items) => {
    var sum = items.reduce((sum, currentItem) => sum + currentItem.count, 0);
    var titleText = isCheckIn ? "Auffüllen" : "Entnahme";
    return sum > 0 ? (
        <p>
            {titleText}
            <Badge>{sum}</Badge>
        </p>
    ) : (
        <p>{titleText}</p>
    );
};

class WarehouseConnected extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.onStorageSelected = this.onStorageSelected.bind(this);
    }

    onStorageSelected(storage) {
        this.props.changeSelectedStorage(storage);
    }

    componentDidMount() {
        document.title = "DRK Lagerverwaltung";
        this.props.fetchDataInitial();
    }

    render() {
        if (localStorage.getItem("token") === null) {
            return <Redirect to={"/login"}/>;
        }
        return <div>
            <Navbar expand="lg">
                <Navbar.Brand>
                    <p>DRK Lagerverwaltung</p>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse>
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/app/equipment">
                                Übersicht
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} to="/app/checkout/">
                                {createCheckInOutTitle(false, this.props.checkoutItems)}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} to="/app/checkin/">
                                {createCheckInOutTitle(true, this.props.checkinItems)}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} to="/app/stocktaking/">
                                Inventur
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <Nav.Item>
                            <Nav.Link as={Link} to="/app/dashboard/">
                                Dashboard
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={this.props.logout}>
                                Logout
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>

            </Navbar>
            <Container>
                <Row>
                    <Col xs={12} md={2}>
                        <h3>Lagerort</h3>
                        <Storage
                            storages={this.props.storages}
                            selected={this.props.selectedStorage}
                            onSelected={this.onStorageSelected}
                        />
                    </Col>
                    <Col xs={12} md={10}>
                        <div>
                            <Route path="/app/equipment" component={Equipment}/>
                            <Route path="/app/checkout/" component={Checkout}/>
                            <Route path="/app/checkin/" component={() => <Checkout checkin/>}/>
                            <Route path="/app/stocktaking/" component={() => <Stocktaking/>}/>
                            <Route path="/app/dashboard/" component={() => <Dashboard/>}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>;
    }
}

const Warehouse = connect(
    mapStateToProps,
    mapDispatchToProps
)(WarehouseConnected);
export default Warehouse;

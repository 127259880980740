import {
  HIDE_EQUIPMENT_DETAIL,
  SHOW_EQUPIMENT_DETAIL
} from "../constants/action-types";

export function hideEquipmentDetail() {
  return {
    type: HIDE_EQUIPMENT_DETAIL
  };
}

export function showEquipmentDetail(item) {
  return {
    type: SHOW_EQUPIMENT_DETAIL,
    payload: item
  };
}

import React from "react";
// import "react-table/react-table.css"; fixme!?
import {Button, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import EquipmentForm from "./equipment_detail_form";
import {hideEquipmentDetail} from "../../redux/actions/equipment-detail-actions";
import {updateEquipmentFromForm} from "../../redux/actions/equipment-actions";

const mapStateToProps = state => {
  return {
    showModal: state.equipmentDetail.show,
    item: {...state.equipmentDetail.item},
    form: state.form.equipmentDetail,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    // This function will be available in component as `this.props.fetchTodos`
    hideModal: function () {
      dispatch(hideEquipmentDetail());
    },
    updateEquipment: function () {
      dispatch(updateEquipmentFromForm());
    }
  };
};

class EquipmentDetailConnected extends React.Component {

  handleSubmit = event => {
    event.preventDefault();
    this.props.updateEquipment();
  };

  render() {
    return <Modal show={this.props.showModal}>
      <Modal.Header>
        <Modal.Title>Objekt bearbeiten</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EquipmentForm handleSubmit={this.handleSubmit} initialValues={this.props.item}/>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.props.hideModal}>Abbrechen</Button>
      </Modal.Footer>
    </Modal>
  }
}

const EquipmentDetail = connect(mapStateToProps, mapDispatchToProps)(EquipmentDetailConnected);
export default EquipmentDetail;

import {LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT} from "../constants/action-types";
import {API_URL} from "../constants/urls";

export const login = (username, password) => {

  return function (dispatch) {
    dispatch({
      type: LOGIN_REQUEST
    });
    const form = new FormData();
    form.append("username", username);
    form.append("password", password);
    return fetch(API_URL + "auth/login/", {
      method: 'POST',
      body: form
    })
    // And providing `response` and `body` variables to the next chain.
      .then(response => response.json().then(body => ({
        response,
        body
      })))
      .then(({
               response,
               body
             }) => {
        if (!response.ok) {
          dispatch({
            type: LOGIN_FAILURE,
            error: body.error
          });
        } else {
          saveAuthToken(body.key);
          dispatch({
            type: LOGIN_SUCCESS,
            payload: body
          });
        }
      })
      .catch(err => {
        dispatch({
          type: LOGIN_FAILURE,
          error: err
        });
      })
  }
};

export const logout = () => {
  return function (dispatch) {
    forgetAuthToken();
    dispatch({type: LOGOUT});
  }
};

const saveAuthToken = token => {
  localStorage.setItem("token", token);
};

const forgetAuthToken = () => {
  localStorage.removeItem("token");
};

export const loginFromForm = () => {
  return (dispatch, getState) => {
    let credentials = getState().form.login.values;
    dispatch(login(credentials.username, credentials.password));
  }
};
import {
  ADD_CHECKOUT_ITEM,
  REMOVE_CHECKOUT_ITEM,
  ADD_CHECKIN_ITEM,
  REMOVE_CHECKIN_ITEM,
  DELETE_CHECKIN_ITEM,
  DELETE_CHECKOUT_ITEM,
  POST_CHECKOUT_REQUEST,
  POST_CHECKOUT_FAILURE,
  POST_CHECKOUT_SUCCESS,
  POST_CHECKIN_REQUEST,
  POST_CHECKIN_FAILURE,
  POST_CHECKIN_SUCCESS
} from "../constants/action-types";

import {fetchEquipments} from "./equipment-actions";
import {API_URL} from "../constants/urls";
import {getStandardHeader} from "../constants/headers";

export const addCheckoutItem = item => {
  return {
    type: ADD_CHECKOUT_ITEM,
    payload: item
  };
};

export const removeCheckoutItem = item => {
  return {
    type: REMOVE_CHECKOUT_ITEM,
    payload: item
  };
};

export const deleteCheckoutItem = item => {
  return {
    type: DELETE_CHECKOUT_ITEM,
    payload: item
  };
};

export const addCheckinItem = item => {
  return {
    type: ADD_CHECKIN_ITEM,
    payload: item
  };
};

export const removeCheckinItem = item => {
  return {
    type: REMOVE_CHECKIN_ITEM,
    payload: item
  };
};

export const deleteCheckinItem = item => {
  return {
    type: DELETE_CHECKIN_ITEM,
    payload: item
  };
};

export function postCheckout() {
  return function (dispatch, getState) {
    var itemsToCheckout = getState().checkoutItems.map(item => {
      return {
        type_id: item.type.id,
        storage_id: item.storage,
        amount: item.count
      };
    });
    dispatch({
      type: POST_CHECKOUT_REQUEST
    });
    return (
      // And providing `response` and `body` variables to the next chain.
      fetchCheckOutIn(false, itemsToCheckout).then(response => {
        if (!response.ok) {
          dispatch({
            type: POST_CHECKOUT_FAILURE,
            payload: response.statusText
          });
        } else {
          dispatch({
            type: POST_CHECKOUT_SUCCESS
          });
          dispatch(fetchEquipments());
        }
      })
    );
  };
}

export function postCheckin() {
  return function (dispatch, getState) {
    var itemsToCheckin = getState().checkinItems.map(item => {
      return {
        type_id: item.type.id,
        storage_id: item.storage,
        amount: item.count
      };
    });
    dispatch({
      type: POST_CHECKIN_REQUEST
    });
    return (
      // And providing `response` and `body` variables to the next chain.
      fetchCheckOutIn(true, itemsToCheckin).then(response => {
        if (!response.ok) {
          dispatch({
            type: POST_CHECKIN_FAILURE,
            payload: response.statusText
          });
        } else {
          dispatch({
            type: POST_CHECKIN_SUCCESS
          });
          dispatch(fetchEquipments());
        }
      }).catch(err => {
        dispatch({
          type:POST_CHECKOUT_FAILURE,
          payload: err
        })
      })
    );
  };
}

const fetchCheckOutIn = (isCheckin, items) => {
  var urlPart = isCheckin ? "checkin" : "checkout";
  return fetch(API_URL + urlPart + "/", {
    method: "POST",
    headers: getStandardHeader(),
    body: JSON.stringify(items)
  });
};

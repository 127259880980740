import {
  FETCH_EQUIPMENT_TYPES_REQUEST,
  FETCH_EQUIPMENT_TYPES_SUCCESS,
  FETCH_EQUIPMENT_TYPES_FAILURE
} from "../constants/action-types";

export function equipmentTypeReducer(state = [], action) {
  switch (action.type) {
    case FETCH_EQUIPMENT_TYPES_REQUEST:
      return state;
    case FETCH_EQUIPMENT_TYPES_SUCCESS:
      return action.payload;
    case FETCH_EQUIPMENT_TYPES_FAILURE:
      return state;
    default:
      return state;
  }
}

import React from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {connect} from "react-redux";
import 'moment-timezone';
import NumberPlateRow from "./dashboard_numberplates";
import StockagePieCard from "./dashboard_stockage_pie";
import {filter_by_storage} from "./dashboard_statistics";

const mapStateToProps = state => {
    return {
        equipment: state.equipment,
        types: state.equipmentTypes,
        storage: state.storages,
        selectedStorage: state.selectedStorage,
    };
};

function mapDispatchToProps(dispatch) {
    return {}
}

const ConnectedDashboard = ({selectedStorage, types, equipment}) => {
    if (!selectedStorage || !types || equipment.length === 0) {
        return <Spinner animation={"grow"}/>
    }
    let filteredEquipment = filter_by_storage(equipment, selectedStorage);
    return <div id={"dashboard"} className={"animated--fade-in"}>
        <h3> Dashboard </h3>
        <Container>
            <NumberPlateRow equipment={filteredEquipment} types={types}/>
            <Row style={{paddingTop: "1.5em"}}>
                <Col>
                    <StockagePieCard equipment={filteredEquipment}/>
                </Col>
            </Row>
        </Container>
    </div>
};

const Dashboard = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedDashboard);
export default Dashboard;

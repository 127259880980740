import {
  FETCH_STORAGES_REQUEST,
  FETCH_STORAGES_SUCCESS,
  FETCH_STORAGES_FAILURE,
  CHANGE_SELECTED_STORAGE,
  SELECT_DEFAULT_STORAGE
} from "../constants/action-types";

export function storagesReducer(state = [], action) {
  switch (action.type) {
    case FETCH_STORAGES_REQUEST:
      return state;
    case FETCH_STORAGES_SUCCESS:
      return action.payload;
    case FETCH_STORAGES_FAILURE:
      return state;
    default:
      return state;
  }
}

export function selectedStorageReducer(state = {name: ""}, action) {
  switch (action.type) {
    case CHANGE_SELECTED_STORAGE:
      return action.payload;
    case SELECT_DEFAULT_STORAGE:
      if (!state.id && action.payload !== undefined) {
        return action.payload;
      }
      return state;
    default:
      return state;
  }
}

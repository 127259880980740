import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import 'moment-timezone';
import {
    calc_number_of_equipments,
    calc_number_of_items_in_stock,
    calc_number_of_understocked_equipments
} from "./dashboard_statistics";

const NumberPlateRow = (props) => {
    let totalNumber_ofItems = calc_number_of_items_in_stock(props.equipment);
    let numberOfEquipments = calc_number_of_equipments(props.equipment);
    let numberOfUnderstocked = calc_number_of_understocked_equipments(props.equipment);

    return <Row>
        <Col>
            <NumberPlate title={"Lagerbestand gesamt"} valueToShow={totalNumber_ofItems} borderColor={"secondary"}/>
        </Col>
        <Col>
            <NumberPlate title={"Bestand nach Typen"} valueToShow={numberOfEquipments} borderColor={"secondary"}/>
        </Col>
        <Col>
            <NumberPlate title={"Fehlbestände"} valueToShow={numberOfUnderstocked} borderColor={"danger"}/>
        </Col>
    </Row>
};


const NumberPlate = ({borderColor, valueToShow, title}) => {
    let borderClass = "border-left-" + borderColor;
    return <Card className={`shadow ${borderClass}`}>
        <Card.Body>
            <NumberPlateValue text={valueToShow}/>
            <NumberPlateTitle text={title}/>
        </Card.Body>
    </Card>
};

const NumberPlateTitle = ({text}) => {
    return <Card.Subtitle>{text}</Card.Subtitle>
};
const NumberPlateValue = ({text}) => {
    return <Card.Title><h4 className={"text-gray-900"}>{text}</h4></Card.Title>
};

export default NumberPlateRow;

import {filterBySearchString, sortBySearchstring} from "../equipment/filtering_tools"

const PK_PREFIX = "**";
const PK_POSTFIX = "**";

export const filterEquipmentTypes = (value, originalEquipments, storageId) => {
    const equipments = originalEquipments.slice();
    const inputValue = value.trim().toLowerCase();

    if (value.startsWith(PK_PREFIX) && value.endsWith(PK_POSTFIX)) {
        return filterByPrimaryKey(inputValue, equipments);
    }
    return filterBySearchStringAndStorage(inputValue, equipments, storageId);

};

export const filterBySearchStringAndStorage = (filterValue, items, storageId) => {
    let results = items.filter(equipment => equipment.storage === storageId);
    results = filterBySearchString(results, filterValue);
    results = sortBySearchstring(results, filterValue);
    return results;
};

export const filterByPrimaryKey = (filterValue, items) => {
    filterValue = filterValue.substring(PK_PREFIX.length, filterValue.length - PK_POSTFIX.length);
    const searchPrimaryKey = Number.parseInt(filterValue, 10);
    return items.filter(
        item => item.type.id === searchPrimaryKey
    );
};

import React from "react";
import {calc_number_of_equipments, calc_number_of_understocked_equipments} from "./dashboard_statistics";
import {ResponsivePie} from "@nivo/pie";
import {Card} from "react-bootstrap";
import {FaCheckCircle} from "react-icons/fa";

const StockagePieCard = ({equipment}) => {
    let number_equipments = calc_number_of_equipments(equipment);
    let number_of_understocked_equipments = calc_number_of_understocked_equipments(equipment);
    let number_of_fully_stocked_equipments = number_equipments - number_of_understocked_equipments;
    if (number_of_understocked_equipments === 0) {
        return <StockFullBadge/>
    }
    let stockageData = [
        {
            id: "Voll",
            label: "Typen mit Vollbestand",
            value: number_of_fully_stocked_equipments,
            color: "var(--success)"
        },
        {
            id: "Fehlbestand",
            label: "Typen mit Fehlbestand",
            value: number_of_understocked_equipments,
            color: "var(--danger)"
        }
    ];

    return <Card className={"shadow border-left-secondary"}>
        <Card.Body>
            <Card.Title>Verhältnis nach Typen</Card.Title>
            <div style={{height: "20em"}}>
                <ResponsivePie data={stockageData} colorBy={"index"} colors={stockageData.map(c => c.color)}
                               innerRadius={0.5}
                               padAngle={0.7}
                               cornerRadius={3}
                               radialLabelsTextColor="var(--gray)"
                               slicesLabelsTextColor="var(--black, #333333)"
                               animate={true}
                               motionStiffness={90}
                               motionDamping={15}
                />
            </div>
        </Card.Body>
    </Card>
};

const StockFullBadge = (props) => {
    return <Card className={"shadow border-left-success"} style={{width: "auto", height: "20em"}}>
        <Card.Body>
            <Card.Title>Lager hat keine Fehlbestände!</Card.Title>
            <span style={{fontSize: "10em", color: "var(--success)"}}>
                <FaCheckCircle/>
            </span>
        </Card.Body>
    </Card>
};
export default StockagePieCard;


import React from "react";
import {Button, ButtonGroup, ButtonToolbar, ListGroupItem,} from "react-bootstrap";
import {FaMinus, FaPlus, FaTrash} from "react-icons/fa";

export const CheckoutEntry = props => {
    const buttonSize = "small";
    const {item, onDelete, onMinus, onPlus} = props;

    return (
        <ListGroupItem key={item.id}>
            {/*{item.id}: */}<b>{item.type.name}</b> - {item.count} Stück
            <ButtonToolbar className="checkout-list--buttongroup">
                <ButtonGroup>
                    <Button size={buttonSize} onClick={() => onPlus(item)}>
                        <FaPlus/>
                    </Button>
                    <Button size={buttonSize} onClick={() => onMinus(item)}>
                        <FaMinus/>
                    </Button>
                </ButtonGroup>
                <Button
                    size={buttonSize}
                    variant="danger"
                    onClick={() => onDelete(item)}
                >
                    <FaTrash/>
                </Button>
            </ButtonToolbar>
        </ListGroupItem>
    );
};

import React from "react";
import {Button} from "react-bootstrap";

const CheckoutButton = props => {
    const buttonTitle = props.checkin ? "Auffüllen" : "Ausbuchen";
    const buttonClass = props.checkin ? "success" : "warning";
    return (
        <Button onClick={props.onClick} disabled={props.disabled} variant={buttonClass} className={"shadow"}>
            {buttonTitle}
        </Button>
    );
};

export default CheckoutButton;

import {
    FETCH_EQUIPMENT_FAILURE,
    FETCH_EQUIPMENT_REQUEST,
    FETCH_EQUIPMENT_SUCCESS,
    FETCH_EQUIPMENT_TYPES_FAILURE,
    FETCH_EQUIPMENT_TYPES_REQUEST,
    FETCH_EQUIPMENT_TYPES_SUCCESS,
    RESET_EXPIRY_FILTER,
    RESET_SEARCH_FILTER,
    TOGGLE_FILTER_UNDERSTOCKED,
    UDPATE_EXPIRY_FILTER,
    UDPATE_SEARCH_FILTER,
    UPDATE_EQUIPMENT_FAILURE,
    UPDATE_EQUIPMENT_REQUEST,
    UPDATE_EQUIPMENT_SUCCESS
} from "../constants/action-types";
import {API_URL} from "../constants/urls";
import {getStandardHeader} from "../constants/headers";

export function fetchEquipments() {
    return function (dispatch, getState) {
        var filterUnderstocked = getState().filterEquipmentUnderstocked;

        dispatch({
            type: FETCH_EQUIPMENT_REQUEST
        });
        return (
            fetch(
                API_URL + "equipment/?format=json&understocked=" + filterUnderstocked, {headers: getStandardHeader()})
                // And providing `response` and `body` variables to the next chain.
                .then(response => response.json().then(body => ({
                    response,
                    body
                })))
                .then(({
                           response,
                           body
                       }) => {
                    if (!response.ok) {
                        dispatch({
                            type: FETCH_EQUIPMENT_FAILURE,
                            error: body.error
                        });
                    } else {
                        dispatch({
                            type: FETCH_EQUIPMENT_SUCCESS,
                            payload: body
                        });
                    }
                })
        );
    };
}

export const fetchEquipmentTypes = () => {
    return function (dispatch) {
        dispatch({
            type: FETCH_EQUIPMENT_TYPES_REQUEST
        });
        return (
            fetch(API_URL + "equipment-type/?format=json", {headers: getStandardHeader()})
                // And providing `response` and `body` variables to the next chain.
                .then(response => response.json().then(body => ({
                    response,
                    body
                })))
                .then(({
                           response,
                           body
                       }) => {
                    if (!response.ok) {
                        dispatch({
                            type: FETCH_EQUIPMENT_TYPES_FAILURE,
                            error: body.error
                        });
                    } else {
                        dispatch({
                            type: FETCH_EQUIPMENT_TYPES_SUCCESS,
                            payload: body
                        });
                    }
                })
        );
    };
};

export const updateEquipment = item => {
    return function (dispatch) {
        dispatch({
            type: UPDATE_EQUIPMENT_REQUEST
        });
        fetch(API_URL + "equipment/" + item.id + "/", {
            method: "PUT",
            headers: getStandardHeader(),
            body: JSON.stringify(item)
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                dispatch({
                    type: UPDATE_EQUIPMENT_SUCCESS,
                    payload: data
                });
                dispatch(fetchEquipments());
            })
            .catch(error => {
                dispatch({
                    type: UPDATE_EQUIPMENT_FAILURE,
                    error: error
                });
            });
    }
};

export const updateEquipmentFromForm = () => {
        return (dispatch, getState) => {
            let item = getState().form.equipmentDetail.values;
            item.type_id = item.type.id;
            dispatch(updateEquipment(item));
        }
    }
;

export const updateSearchFilter = (searchText) => {
    return function (dispatch) {
        dispatch({
            type: UDPATE_SEARCH_FILTER,
            payload: searchText
        });
    };
};

export const resetSearchFilter = () => {
    return function (dispatch) {
        dispatch({
            type: RESET_SEARCH_FILTER,
        });
    };
};

export const toggleFilterUnderstocked = (filterState) => {
    return function (dispatch) {
        dispatch({
            type: TOGGLE_FILTER_UNDERSTOCKED,
            payload: filterState
        });
    };
};

export const updateExpiryFilter = (latestExpiryDate) => {
    return function (dispatch) {
        dispatch({
            type: UDPATE_EXPIRY_FILTER,
            payload: latestExpiryDate
        });
    };

};

export const resetExpiryFilter = () => {
    return function (dispatch) {
        dispatch({
            type: RESET_EXPIRY_FILTER,
        });
    };

};

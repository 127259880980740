import {combineReducers} from "redux";
import {checkinLoadingReducer, checkinReducer, checkoutLoadingReducer, checkoutReducer} from "./checkout-reducers";
import {
    equipmentDetailReducer, equipmentExpiryReducer,
    equipmentLoadingReducer,
    equipmentReducer,
    equipmentUnderstockedReducer,
    equipmentUpdateSearchFilterReducer,
    equipmentUpdatingReducer
} from "./equipment-reducers";
import {selectedStorageReducer, storagesReducer} from "./storages-reducers";
import {equipmentTypeReducer} from "./equipment-types-reducers";
import {reducer as formReducer} from 'redux-form';
import {authReducer} from "./auth-reducers";

export default combineReducers({
    auth: authReducer,
    equipment: equipmentReducer,
    equipmentDetail: equipmentDetailReducer,
    equipmentTypes: equipmentTypeReducer,
    filterEquipmentUnderstocked: equipmentUnderstockedReducer,
    equipmentSearchFilter: equipmentUpdateSearchFilterReducer,
    equipmentExpiryFilter: equipmentExpiryReducer,
    checkinItems: checkinReducer,
    checkoutItems: checkoutReducer,
    storages: storagesReducer,
    selectedStorage: selectedStorageReducer,
    checkoutLoading: checkoutLoadingReducer,
    checkinLoading: checkinLoadingReducer,
    equipmentLoading: equipmentLoadingReducer,
    equipmentUpdating: equipmentUpdatingReducer,
    form: formReducer
});
import {LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT} from "../constants/action-types";

export function authReducer(state = {}, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {loading:true};
    case LOGIN_SUCCESS:
      return {token: action.payload.key};
    case LOGIN_FAILURE:
      return {failure: true};
    case LOGOUT:
      return {};
    default:
      return state;
  }
}